<script>
import Multiselect from "@vueform/multiselect";
import API from "@/plugins/axios-method";
export default {
  name: "filter-form",
  components: {Multiselect},
  data(){
    return {
      selectedMemberType: null,
      selectedCities: null,
      memberTypes: [],
      cities: [],
      isSubmitted: false,
    }
  },
  mounted() {
    this.getMemberType()
    this.getCitiesWithUsers()
  },
  methods: {
    submitFilter(){
      const selectedCitiesString = this.cities.filter(city => this.selectedCities.some(selectedCity => city.uniqId === selectedCity.split('/api/cities/')[1])).map(c => c.id).join(',')
      this.isSubmitted = true
      this.$emit('filterSubmitted', {
        memberType: this.selectedMemberType,
        cities: this.selectedCities,
        selectedCitiesString
      })
      this.isSubmitted = false
    },
    getMemberType(){
      API.get('/constant/member_type')
          .then(response => {
            response.data['hydra:member'].map((memberType) => {
              this.memberTypes.push({
                value: memberType,
                label: memberType
              })
            })
          })
    },
    getCitiesWithUsers() {
      API.get('/cities_with_users')
          .then(response => {
            response.data['hydra:member'].map((city) => {
              this.cities.push({
                value: city['@id'],
                label: city.name,
                id: city.id,
                uniqId: city.uniqId,
                // label: city.name + ` (${city.userCount})`,
              })
            })
          })
          .finally(() => {
            this.$emit('cities-loaded', true)
          })
    },
  },

}
</script>

<template>
  <div class="card border border-end-0 border-start-0 p-4 bg-white">
    <form @submit.prevent="submitFilter">
      <div class="row g-3 justify-content-end align-items-center">
        <div class="col-xxl-2 col-sm-4">
          <div>
            <label for="member">Type d'adhérent</label>
            <Multiselect
                id="member"
                required
                class="form-control"
                v-model="selectedMemberType"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :options="memberTypes" />
          </div>
        </div>
        <!--end col-->
        <div class="col-xxl-4 col-sm-6">
          <div>
            <label for="cities">Villes</label>
            <Multiselect
                id="cities"
                required
                class="form-control"
                v-model="selectedCities"
                :close-on-select="true"
                :searchable="true"
                :create-option="true"
                :mode="'tags'"
                :options="cities" />
          </div>
        </div>
        <div class="col-xxl-1 col-sm-2 mt-md-auto">
          <div>
            <button :disabled="isSubmitted" class="btn btn-primary w-100" type="submit">
              <div v-if="isSubmitted" style="width: 20px; height: 20px" class="text-white spinner-border text-primary avatar-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <span v-if="!isSubmitted"><i class="ri-equalizer-fill me-1 align-bottom"></i> Filtrer</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>

</style>